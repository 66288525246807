<template>
  <div class="recommend">
    <div class="warpper">
      <div class="swiper">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #5f6bc1">M</div>
                <div class="info">
                  <div class="name">Michael M.</div>
                  <div class="title">Rosebery, NSW</div>
                </div>
              </div>
              <div class="desc">
                Submitted details online, they reached out and had an inspection
                the next day at my house. Negotiated on the price and they
                agreed to it. FedEx them the title, once they have that, they
                wire me the money same day and then they collect the cat after I
                get my money.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #6d9e42">R</div>
                <div class="info">
                  <div class="name">Rosalie H.</div>
                  <div class="title">Brentwood, WA</div>
                </div>
              </div>
              <div class="desc">
                Sold my Tesla through them and the whole process was handled
                perfectly. It was fast and easy, they communicated with me the
                whole time and didn’t pick up the car until I had been wired the
                money to my account.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #e9497d">J</div>
                <div class="info">
                  <div class="name">James Y.</div>
                  <div class="title">Mascot, NSW</div>
                </div>
              </div>
              <div class="desc">
                Extremely pleased with quotemycars.com.au.Everyone I spoke to
                was very professional and fair. They assisted me through this
                seamless process every step of the way. I will definitely be
                recommending friends and family in the future!
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #5f6bc1">K</div>
                <div class="info">
                  <div class="name">Kaylene M.</div>
                  <div class="title">Mudgeeraba, QLD</div>
                </div>
              </div>
              <div class="desc">
                I have never sold a car this way and from the very beginning
                Toby, the guy that did my inspection, any and everyone I came
                into contact with was friendly, professional and helpful. I
                would definitely recommend this service to everyone!
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #6d9e42">B</div>
                <div class="info">
                  <div class="name">Brett A.</div>
                  <div class="title">Mount Barker, SA</div>
                </div>
              </div>
              <div class="desc">
                Great professional service from Toby, and my inspector
                Aaron!Never sold a vehicle quite this easy.Fast,convenient, good
                communication from everyone.Got a fair amount for my truck.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #e9497d">E</div>
                <div class="info">
                  <div class="name">Erik P.</div>
                  <div class="title">Sebastopol, VIC</div>
                </div>
              </div>
              <div class="desc">
                I worked with Brad at quotemycars.com.au. I was very skeptical
                at first it's very scary if you have never sold a car online but
                these guys are the real deal very professional Brad was quick to
                get back with me every time I had a question and the money was
                deposited in my account exactly when he said it would be I would
                recommend them to anyone ! Thank you !
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #5f6bc1">M</div>
                <div class="info">
                  <div class="name">Martin M.</div>
                  <div class="title">New Beith, QLD</div>
                </div>
              </div>
              <div class="desc">
                I was really impressed with how quick and efficient the process
                was. Their offer on my car was amazing, in fact it was more than
                I paid when I bought it new. Within about 7 days start to
                finish, an inspector examined my car, we agreed on a final
                offer, I mailed them the title, the money appeared in my
                account, and someone came and picked it up. I was amazed and how
                quickly and smoothly it went. Thank you!
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-side">
            <div class="item">
              <div class="top">
                <div class="avatar" style="background: #6d9e42">G</div>
                <div class="info">
                  <div class="name">Greg K.</div>
                  <div class="title">Port Lincoln, SA</div>
                </div>
              </div>
              <div class="desc">
                Quote my cars was easier than I thought it would be. I filled
                out the online application with the info of my Jeep. I received
                an online quote right away. A friendly representative called me
                requesting photos of my car and explained multiple options for
                delivery. After the car got to it's destination I received a a
                direct deposit in my bank account. I was very happy happy with
                the ease of the transaction.
              </div>
              <div class="rank">
                <img src="~@/assets/images/pinfen.png" alt="" />
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
        <!--分页器。如果放置在swiper外面，需要自定义样式。-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: false,
        slidesPerView: "auto",
        spaceBetween: 80,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          bulletElement: "li",
        },
      },
    };
  },
};
</script>

<style scoped lang="less">
.recommend {
  width: 100%;
  background: url("~@/assets/images/banner4.png") no-repeat;
  background-size: cover;
  background-position: center;
  .warpper {
    padding: 120px 0;
    .swiper {
      width: 100%;
      .swiper-side {
        width: 1200px;
        .item {
          margin: 0 auto;
          width: 1200px;
          height: 320px;
          padding: 40px;
          border-radius: 8px;
          background: #fff;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .top {
            display: flex;
            align-items: center;
            .avatar {
              width: 52px;
              height: 52px;
              border-radius: 50%;
              text-align: center;
              line-height: 52px;
              font-family: Ubuntu-Bold, Ubuntu;
              font-size: 24px;
              color: #fff;
            }
            .info {
              margin-left: 20px;
              .name {
                font-family: Ubuntu-Bold, Ubuntu;
                font-size: 16px;
                line-height: 16px;
                font-weight: 700;
                color: #212121;
              }
              .title {
                font-family: Ubuntu-Regular, Ubuntu;
                font-size: 16px;
                line-height: 16px;
                font-weight: 700;
                color: rgba(0, 0, 0, 0.5);
                margin-top: 12px;
              }
            }
          }
          .desc {
            font-family: Ubuntu-Regular, Ubuntu;
            font-size: 16px;
            font-weight: 700;
            color: #212121;
            line-height: 32px;
            margin-top: 30px;
          }
          .rank {
            height: 32px;
            img {
              height: 100%;
            }
          }
        }
      }
      .swiper-pagination {
        width: 100%;
        height: 24px;
        /deep/ .swiper-pagination-bullet {
          background: rgba(255, 255, 255, 0.8);
          margin: 0 5px;
        }
        /deep/ .swiper-pagination-bullet-active {
          background: #fff;
        }
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .recommend {
    // height: 2120px;
    width: 100%;
    .warpper {
      padding: 40px 0;
      .swiper {
        .swiper-side {
          width: 375px;
          .item {
            width: 450px;
            height: 175px;
            padding: 10px;
            .top {
              .avatar {
                width: 24px;
                height: 24px;
                line-height: 24px;
                font-size: 16px;
              }
              .info {
                margin-left: 12px;
                .name {
                  font-size: 12px;
                  line-height: 14px;
                }
                .title {
                  margin-top: 4px;
                  font-size: 10px;
                  line-height: 12px;
                }
              }
            }
            .desc {
              margin-top: 10px;
              font-size: 10px;
              font-family: Ubuntu-Regular, Ubuntu;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.66);
              line-height: 12px;
            }
            .rank {
              height: 16px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .recommend {
    // height: 2120px;
    width: 100%;
    .warpper {
      padding: 40px 0;
      .swiper {
        .swiper-side {
          width: 375px;
          .item {
            width: 350px;
            height: 175px;
            padding: 10px;
            .top {
              .avatar {
                width: 24px;
                height: 24px;
                line-height: 24px;
                font-size: 16px;
              }
              .info {
                margin-left: 12px;
                .name {
                  font-size: 12px;
                  line-height: 14px;
                }
                .title {
                  margin-top: 4px;
                  font-size: 10px;
                  line-height: 12px;
                }
              }
            }
            .desc {
              margin-top: 10px;
              font-size: 10px;
              font-family: Ubuntu-Regular, Ubuntu;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.66);
              line-height: 12px;
            }
            .rank {
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
